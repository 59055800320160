<script>
export default {
  name: "DistributeHistory",
  data() {
    return {
      dialogFormRecord: false,
      recordParams: {
        orderId: "",
      },
      recordList: [],
      isRecordEntryEmpty: true,
      isRecordEntry: false,
      isLoadRecordData: true,
    };
  },
  methods: {
    open(id) {
      this.recordParams.orderId = id;
      this.queryOperateRecordList(id);
      this.dialogFormRecord = true;
    },
    close() {
      this.dialogFormRecord = false;
      // this.$nextTick(() => {
      //   this.recordList = [];
      //   this.isRecordEntryEmpty = true;
      //   this.isRecordEntry = false;
      //   this.isLoadRecordData = true;
      // });
    },
    queryOperateRecordList(id) {
      this.isLoadRecordData = true;
      this.axios({
        method: "get",
        url: `/v1/web/device-manage/distribution-history/${id}`,
      })
        .then((res) => {
          this.recordList = res.data.data.list;
          if (this.recordList.length > 0) {
            this.isRecordEntryEmpty = false;
            this.isRecordEntry = true;
            this.isLoadRecordData = false;
          }else{
            this.isRecordEntryEmpty = true;
            this.isRecordEntry = false;
            this.isLoadRecordData = false;
          }
        })
        .finally(() => {
          setInterval(() => {
            this.isLoadRecordData = false;
          }, 500);
        });
    },
  },
};
</script>
<template>
  <el-dialog
    title="分配历史记录"
    :visible.sync="dialogFormRecord"
    width="800px"
    height="480px"
    :modal="true"
    :lock-scroll="true"
    :close-on-click-modal="false"
  >
    <div v-loading="isLoadRecordData">
      <el-empty
        description="订单无分配历史记录~"
        v-show="isRecordEntryEmpty && !isLoadRecordData"
      ></el-empty>
      <el-timeline
        :reverse="recordParams.reverse"
        v-show="isRecordEntry && !isLoadRecordData"
      >
        <el-timeline-item
          v-for="(record, index) in recordList"
          :key="index"
          :timestamp="$CreateTimeFormat(record.operateTime)"
          placement="top"
        >
          <el-descriptions :title="record.operationType" direction="horizontal" :column="1">
            <el-descriptions-item label="拥有人" v-if='record.operationType=="分配"'>
              {{ record.haveUser }}
            </el-descriptions-item>
            <el-descriptions-item label="操作人">
              {{ record.distributionUser }}
            </el-descriptions-item>
          </el-descriptions>
        </el-timeline-item>
      </el-timeline>
    </div>
    <div slot="footer" style="margin-top: 10px">
      <el-button type="info" size="mini" @click="close"> 取消 </el-button>
    </div>
  </el-dialog>
</template>
<style scoped></style>
