<template>
  <div style="height: 100%">
    <div style="padding: 20px; height: 800px">
      <!-- 搜素框 -->
      <el-form
        :inline="true"
        :model="params"
        style="
          padding-top: 20px;
          padding-left: 20px;
          margin-bottom: 20px;
          background-color: #fff;
          border-radius: 8px;
        "
        size="small"
      >
        <el-form-item label="充电桩编号">
          <el-input v-model="params.deviceNum" placeholder="请输入充电桩编号"></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="params.status" placeholder="请选择状态">
            <el-option label="未使用" value="0"></el-option>
            <el-option label="已使用" value="1"></el-option>
            <el-option label="转借中" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属类型">
          <el-select v-model="params.categoryId" placeholder="请选择类型">
            <el-option
              v-for="item in classListQuery"
              :key="item.id"
              :label="item.categoryName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属公司">
          <el-select v-model="params.companyId" placeholder="请选择公司">
            <el-option
              v-for="item in companyDrownList"
              :key="item.companyId"
              :label="item.companyManager"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否坏桩">
          <el-select v-model="params.isBad" placeholder="请选择是否坏桩型">
            <el-option label="全部" value=""></el-option>
            <el-option label="否" value="0"></el-option>
            <el-option label="是" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否盘点">
          <el-select v-model="params.isInventory" placeholder="请选择是否盘点">
            <el-option label="全部" value="0"></el-option>
            <el-option label="否" value="-1"></el-option>
            <el-option label="是" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属区域" prop="regionId">
          <el-select filterable v-model="params.regionId" placeholder="请选择">
            <el-option
              v-for="item in regionList"
              :key="item.id"
              :label="item.regionName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属师傅">
          <el-select filterable v-model="params.haveUser" placeholder="请选择">
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时间范围">
          <!-- <el-date-picker
						v-model="params.beginTime"
						type="datetime"
						placeholder="开始时间"
						value-format="timestamp"
						style="width: 180px"
					></el-date-picker>
					<el-date-picker
						default-time="23:59:59"
						v-model="params.endTime"
						type="datetime"
						placeholder="结束时间"
						value-format="timestamp"
						style="margin-left: 5px; width: 180px"
					></el-date-picker> -->

          <el-date-picker
            style="width: 220px"
            v-model="recordTime"
            type="datetimerange"
            range-separator="至"
            value-format="timestamp"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button @click="checkData" type="primary" size="mini">查询</el-button>
          <el-button type="info" @click="reset" size="mini">重置</el-button>
          <el-button
            @click="toDayList(true)"
            type="primary"
            size="mini"
            :style="{
              border: isTodayQuery ? '3px solid #ff0000ad' : '',
              borderRadius: isTodayQuery ? '5px' : '',
            }"
            >当前账户今日分配</el-button
          >
          <!-- <el-button @click="toDayList(false)" type="primary" size="mini">今日分配</el-button> -->
        </el-form-item>
      </el-form>
      <!-- 表格 -->
      <div style="height: 100%; background-color: #fff; border-radius: 8px">
        <div style="width: 98%; margin: auto; height: 80%">
          <div style="float: right; margin: 10px 10px 15px 0">
            <el-button
              type="primary"
              size="small"
              @click="add()"
              v-show="authorization('/home-admin/device/device-manage/add')"
              >新增</el-button
            >
            <el-button
              type="primary"
              size="small"
              @click="addBatch()"
              v-show="authorization('/home-admin/device/device-manage/addBatch')"
              >批量新增</el-button
            >
            <el-button
              type="success"
              v-show="authorization('/home-admin/device/device-manage/distribution')"
              @click="distribution()"
              size="small"
              >分配</el-button
            >
            <el-button
              type="danger"
              v-show="authorization('/home-admin/device/device-manage/unDistribution')"
              @click="unDistribution()"
              size="small"
              >取消分配</el-button
            >
            <el-button
              type="primary"
              @click="borrow()"
              size="small"
              disabled
              v-show="authorization('/home-admin/device/device-manage/borrow')"
              >设备转借</el-button
            >
            <el-dropdown
              split-button
              type="warning"
              size="small"
              @click="toExcel(0)"
              @command="toExcel(1)"
              style="margin-left: 10px"
              v-loading="exportExcelLoading"
              v-show="authorization('/home-admin/device/device-manage/export')"
            >
              导出选中
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>导出全部</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <el-table
            :data="tableData"
            stripe
            size="small"
            height="90%"
            style="margin-left: 20px; width: 98%"
            :header-cell-style="{
              'background-color': '#84aace4a',
              color: '#343333',
              'text-align': 'center',
            }"
            :cell-style="{ 'text-align': 'center' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="45"></el-table-column>
            <el-table-column
              prop="deviceNum"
              label="充电桩编号"
              width="180"
            ></el-table-column>
            <el-table-column
              prop="categoryName"
              label="所属类型"
              width="150"
            ></el-table-column>
            <el-table-column
              prop="companyName"
              label="所属公司"
              width="150"
            ></el-table-column>
            <!--        <el-table-column prop="sapNum" label="ASP编号"></el-table-column>-->
            <el-table-column
              prop="status"
              label="状态"
              :formatter="statusFormat"
              width="80"
            ></el-table-column>
            <!--        <el-table-column prop="describe" label="描述"></el-table-column>-->
            <el-table-column
              prop="isBad"
              label="是否坏桩"
              :formatter="isBadFormat"
              width="80"
            ></el-table-column>
            <el-table-column prop="haveUserName" label="拥有人"></el-table-column>
            <el-table-column prop="createUserName" label="录入人"></el-table-column>
            <el-table-column
              prop="createTime"
              width="150"
              label="录入时间"
              :formatter="dateFormat"
            ></el-table-column>
            <el-table-column prop="distributionUserName" label="分配人"></el-table-column>
            <el-table-column
              prop="distributionTime"
              width="150"
              label="分配时间"
              :formatter="dateFormat"
            ></el-table-column>
            <el-table-column prop="outUserName" label="使用人"></el-table-column>
            <el-table-column
              prop="useTime"
              width="150"
              label="使用时间"
              :formatter="dateFormat"
            ></el-table-column>
            <el-table-column
              prop="inventoryTime"
              width="150"
              label="盘点时间"
              :formatter="dateFormat"
            ></el-table-column>
            <el-table-column prop="imageUrl" label="盘点信息" width="160px">
              <template slot-scope="scope">
                <el-image
                  v-for="(url, index) in getImageList(scope.row.imageUrl)"
                  :key="index"
                  :src="url"
                  :preview-src-list="getImageList(scope.row.imageUrl)"
                  style="height: 20px; margin-right: 5px; float: left"
                ></el-image>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="140px" fixed="right">
              <template slot-scope="scope">
                <el-tooltip
                  effect="dark"
                  content="盘点"
                  placement="top-end"
                  v-show="authorization('/home-admin/device/device-manage/inventory')"
                >
                  <el-button
                    type="primary"
                    icon="el-icon-s-claim"
                    circle
                    size="mini"
                    @click="edit(scope.row)"
                    style="margin-right: 5px"
                  ></el-button>
                </el-tooltip>
                <el-tooltip effect="dark" content="分配历史" placement="top-end">
                  <el-button
                    type="primary"
                    content="分配历史"
                    icon="el-icon-document"
                    style="margin-left: 0px; margin-right: 5px"
                    circle
                    size="mini"
                    @click="distributeHistoryOpen(scope.row.id)"
                    v-show="authorization('/home-admin/device/device-manage/inventory')"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip
                  effect="dark"
                  content="删除"
                  placement="top-end"
                  v-show="authorization('/home-admin/device/device-manage/del')"
                >
                  <el-popconfirm title="确认删除吗？" @confirm="deleteDevice(scope.row)">
                    <el-button
                      content="删除"
                      slot="reference"
                      type="danger"
                      icon="el-icon-delete"
                      circle
                      size="mini"
                    ></el-button>
                  </el-popconfirm>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <div
            style="
              padding-top: 20px;
              height: 40px;
              background-color: #fff;
              width: 100%;
              border-radius: 8px;
              text-align: right;
            "
          >
            <el-pagination
              background
              :page-sizes="sizes"
              layout="total,sizes,prev,pager,next"
              :total="total"
              @current-change="handleCurrentChange"
              @size-change="sizeChange"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- 弹出层 -->
    <el-dialog
      title="新增"
      :visible.sync="dialogFormAdd"
      width="700px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceManageArray"
        :rules="rules"
        ref="deviceManageArray"
        label-width="160px"
        size="small"
        style="width: 600px"
      >
        <el-form-item
          label="充电桩编号"
          prop="deviceNums"
          v-show="isAddBatch"
          :rules="isAddBatch ? rules.deviceNums : [{ required: false }]"
        >
          <el-input
            type="textarea"
            :rows="4"
            v-model="deviceManageArray.deviceNums"
            placeholder="请输入充电桩编号,多个以换行隔开"
          ></el-input>
        </el-form-item>
        <el-form-item label="充电桩编号" prop="deviceNum" v-show="!isAddBatch">
          <el-input v-model="deviceManageArray.deviceNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item
          label="设备数量"
          prop="count"
          v-show="
            (deviceManageArray.deviceNum == null || deviceManageArray.deviceNum == '') &&
            !isAddBatch
          "
        >
          <el-input v-model="deviceManageArray.count" type="number"></el-input>
        </el-form-item>
        <el-form-item label="所属类型" prop="categoryId">
          <el-select
            v-model="deviceManageArray.categoryId"
            placeholder="请选择类型"
            style="width: 100%"
          >
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.categoryName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属公司" prop="companyId">
          <el-select
            filterable
            v-model="deviceManageArray.companyId"
            placeholder="请选择所属公司"
            style="width: 100%"
          >
            <el-option
              v-for="item in companyDrownList"
              :key="item.companyId"
              :label="item.companyManager"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="所属师傅"
          v-show="!authorization('/device-data-manager-addForMe')"
        >
          <el-select
            filterable
            v-model="deviceManageArray.haveUser"
            placeholder="请选择所属师傅"
            style="width: 100%"
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 50px">
        <el-button @click="dialogFormAdd = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="addClick('deviceManageArray')" size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 编辑弹出层 -->
    <el-dialog
      title="设备盘点"
      :visible.sync="dialogFormEdit"
      width="700px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceManageArray"
        ref="deviceManageArrayEdit"
        label-width="160px"
        size="small"
        style="width: 600px"
      >
        <el-form-item label="所属类型" prop="categoryId">
          <el-select
            disabled
            v-model="deviceManageArray.categoryId"
            placeholder="请选择类型"
            style="width: 100%"
          >
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.categoryName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属公司" prop="companyId">
          <el-select
            filterable
            v-model="deviceManageArray.companyId"
            placeholder="请选择所属公司"
            style="width: 100%"
          >
            <el-option
              v-for="item in companyDrownList"
              :key="item.companyId"
              :label="item.companyManager"
              :value="item.companyId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="充电桩编号" prop="deviceNum">
          <el-input
            v-model="deviceManageArray.deviceNum"
            autocomplete="off"
            :disabled="
              !authorization('/home-admin/device/device-manage/inventory-deviceid')
            "
          ></el-input>
        </el-form-item>
        <el-form-item
          label="使用状态"
          prop="status"
          v-show="authorization('/home-admin/device/device-manage/inventory-status')"
        >
          <template>
            <el-radio v-model="deviceManageArray.status" :label="0">未使用</el-radio>
            <el-radio v-model="deviceManageArray.status" :label="1">已使用</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="是否坏桩" prop="isBad">
          <template>
            <el-radio v-model="deviceManageArray.isBad" :label="0">否</el-radio>
            <el-radio v-model="deviceManageArray.isBad" :label="1">是</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="充电桩图片">
          <el-upload
            action="#"
            accept="image/jpeg,image/png,image/tiff,image/vnd.svf"
            :file-list="deviceManageArray.imageList"
            list-type="picture-card"
            :auto-upload="false"
            :on-change="(file, fileList) => upload(file, fileList)"
            :on-remove="commRemove"
            :on-preview="handlePictureCardPreview"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            :rows="4"
            v-model="deviceManageArray.describe"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 50px">
        <el-button @click="dialogFormEdit = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="editSubMit('deviceManageArrayEdit')" size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="分配"
      :visible.sync="dialogFormDistribution"
      width="700px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceManageArray"
        :rules="rules"
        ref="deviceManageArray"
        label-width="160px"
        size="small"
        style="width: 600px"
      >
        <el-form-item
          label="设备类型"
          prop="categoryId"
          :rules="
            deviceManageArray.selectDeviceList == null ||
            deviceManageArray.selectDeviceList.length <= 0
              ? rules.categoryId
              : [{ required: false }]
          "
          v-show="
            deviceManageArray.selectDeviceList == null ||
            deviceManageArray.selectDeviceList.length <= 0
          "
        >
          <el-select
            v-model="deviceManageArray.categoryId"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.categoryName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="设备数量"
          prop="count"
          :rules="
            deviceManageArray.selectDeviceList == null ||
            deviceManageArray.selectDeviceList.length <= 0
              ? rules.count
              : [{ required: false }]
          "
          v-show="
            deviceManageArray.selectDeviceList == null ||
            deviceManageArray.selectDeviceList.length <= 0
          "
        >
          <el-input
            type="number"
            v-model="deviceManageArray.count"
            placeholder="请输入"
            style="width: 100%"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="负责师傅"
          prop="haveUser"
          :rules="[{ required: true, message: '请选择负责师傅', trigger: 'blur' }]"
        >
          <el-select
            filterable
            v-model="deviceManageArray.haveUser"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in sortedA"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 50px">
        <el-button @click="dialogFormDistribution = false" size="mini">取 消</el-button>
        <el-button
          type="primary"
          @click="distributionSubMit('deviceManageArray')"
          size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="设备转借"
      :visible.sync="dialogFormBorrow"
      width="700px"
      :close-on-click-modal="false"
    >
      <el-form
        :model="deviceManageBorrow"
        :rules="rules1"
        ref="deviceManageBorrow"
        label-width="160px"
        size="small"
        style="width: 600px"
      >
        <el-form-item label="借给谁" prop="inputUser">
          <el-select
            filterable
            v-model="deviceManageBorrow.inputUser"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in userList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceIds">
          <el-select
            v-model="deviceManageBorrow.deviceIds"
            filterable
            multiple
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in changeDeviceList"
              :key="item.id"
              :label="item.deviceNum + '(' + item.categoryName + ')'"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="设备类型"
          prop="categoryId"
          v-show="
            deviceManageBorrow.deviceIds == null ||
            deviceManageBorrow.deviceIds.length <= 0
          "
          :rules="
            deviceManageBorrow.deviceIds == null ||
            deviceManageBorrow.deviceIds.length <= 0
              ? rules.categoryId
              : [{ required: false }]
          "
        >
          <el-select
            v-model="deviceManageBorrow.categoryId"
            placeholder="请选择"
            style="width: 100%"
          >
            <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.categoryName"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="设备数量"
          prop="count"
          v-show="
            deviceManageBorrow.deviceIds == null ||
            deviceManageBorrow.deviceIds.length <= 0
          "
          :rules="
            deviceManageBorrow.deviceIds == null ||
            deviceManageBorrow.deviceIds.length <= 0
              ? rules.count
              : [{ required: false }]
          "
        >
          <el-input
            type="number"
            v-model="deviceManageBorrow.count"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" style="margin-top: 50px">
        <el-button @click="dialogFormBorrow = false" size="mini">取 消</el-button>
        <el-button type="primary" @click="borrowSubMit('deviceManageBorrow')" size="mini"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogImagePreview">
      <img width="100%" :src="imagePreviewUrl" alt="" />
    </el-dialog>
    <!-- 驳回历史 -->
    <DistributeHistory ref="distributeHistory" />
  </div>
</template>

<script>
import moment from "moment";
import { authorization } from "../../../authorization/authorization";
import ImageCompressor from "compressorjs";
import DistributeHistory from "@/components/admin/device/components/DistributeHistory";
import { getBData } from "@/utils/storage.js";
import { saveUserSortSimple } from "@/utils/userSort.js";
const STORE_KEY = "deviceUserSort";
export default {
  components: { DistributeHistory },
  data() {
    return {
      sortUserList: [],
      recordTime: [],
      regionList: [],
      companyDrownList: [],
      exportExcelLoading: false,
      input: "",
      dialogFormAdd: false,
      dialogFormEdit: false,
      isAddBatch: false,
      dialogImagePreview: false,
      imagePreviewUrl: "",
      tableData: [],
      params: {
        deviceNum: "",
        brand: "",
        sapNum: "",
        status: "",
        categoryId: "",
        beginTime: "",
        endTime: "",
        regionId: "",
        haveUser: "",
        page: 1,
        size: 10,
      },
      deviceManageBorrow: {
        count: "",
        categoryId: "",
      },
      deviceManageArray: {
        id: "",
        deviceNum: "",
        brand: "",
        sapNum: "",
        describe: "",
        haveUser: "",
        categoryId: "",
        inputUser: "",
        count: "",
        deviceIdList: [],
        imageList: [],
      },
      rules: {
        count: [{ required: true, message: "请输入数量", trigger: "blur" }],
        categoryId: [{ required: true, message: "请选择类型", trigger: "blur" }],
        deviceNums: [{ required: true, message: "请输入充电桩编号", trigger: "blur" }],
        companyId: [{ required: true, message: "请选择所属公司", trigger: "blur" }],
      },
      rules1: {
        haveUser: [{ required: true, message: "请选择所属师傅", trigger: "blur" }],
        inputUser: [{ required: true, message: "请选择转借给谁", trigger: "blur" }],
        categoryId: [{ required: true, message: "请选择类型", trigger: "blur" }],
      },
      orderIdList: [],
      userList: [],
      dialogFormDistribution: false,
      dialogFormBorrow: false,
      total: 0,
      sizes: [10, 15],
      classListQuery: [],
      classList: [],
      changeDeviceList: [],
      multipleSelection: [],
    };
  },
  computed: {
    // 计算属性，按照 b 数组根据 a 数组顺序排序
    sortedA() {
      const indexMap = new Map(this.sortUserList.map((id, index) => [id, index]));
      return [...this.userList].sort((itemA, itemB) => {
        const indexA = indexMap.get(itemA.id);
        const indexB = indexMap.get(itemB.id);

        // 处理 indexA 和 indexB 为空的情况
        if (indexA === undefined && indexB === undefined) {
          return 0; // 如果两个都不存在，保持原有顺序
        } else if (indexA === undefined) {
          return 1; // itemA 不在 indexMap 中，排在后面
        } else if (indexB === undefined) {
          return -1; // itemB 不在 indexMap 中，排在后面
        }

        return indexB - indexA; // 排序逻辑
      });
    },
  },
  created() {
    this.queryDeviceManageList();
    this.queryUserList();
    this.queryClassList();
    this.queryRegionList();
    this.getCompanyDrown();
  },
  mounted() {
    this.sortUserList = getBData(STORE_KEY);
  },
  methods: {
    distributeHistoryOpen(id) {
      this.$refs.distributeHistory.open(id);
    },
    authorization,
    getCompanyDrown() {
      this.axios({
        method: "get",
        url: "/v1/web/company-manager/select-company-drown",
      }).then((res) => {
        this.companyDrownList = res.data.data;
      });
    },
    queryRegionList() {
      //所属区域下拉
      this.axios({
        method: "get",
        url: "/v1/web/region-manage/select-region-drop-down",
        params: this.params,
      }).then((res) => {
        this.regionList = [];
        let obj = {};
        obj.id = 0;
        obj.regionName = "全部";
        this.regionList.push(obj);
        this.regionList.push(...res.data.data.list);
      });
    },
    toExcel(exportType) {
      this.exportExcelLoading = true;
      let params = { ...this.params };
      if (exportType == 0) {
        if (this.multipleSelection.length <= 0) {
          this.$message({ message: "请至少勾选一个订单项", type: "error" });
          return;
        }

        this.deviceIds = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          this.deviceIds.push(this.multipleSelection[i].id);
        }
        params.deviceIds = this.deviceIds;
      } else {
        params.deviceIds = [];
      }

      this.axios({
        method: "post",
        url: "/v1/web/device-manage/device-export-excel",
        data: params,
        responseType: "blob",
      }).then((res) => {
        let filename = res.headers["content-disposition"];
        const link = document.createElement("a");
        let blob = new Blob([res.data], { type: "application/octet-stream" }); //类型excel
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute(
          "download",
          decodeURI(filename.substring(filename.indexOf("=") + 1))
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.exportExcelLoading = false;
      });
    },
    getDeviceByCategory() {
      this.axios({
        method: "get",
        url: "/v1/web/device-manage/list-device-manage",
        params: {
          status: 0,
          numNotNull: "true",
        },
      }).then((res) => {
        this.changeDeviceList = res.data.data.list;
      });
    },
    queryClassList() {
      this.axios({
        method: "get",
        url: "/v1/web/device-category/select-category",
      }).then((res) => {
        let obj = {};
        obj.id = -1;
        obj.categoryName = "全部";
        this.classListQuery.push(obj);
        this.classListQuery.push(...res.data.data.list);
        this.classList.push(...res.data.data.list);
      });
    },
    queryDeviceManageList(params) {
      if (this.recordTime.length > 0) {
        this.params.beginTime = this.recordTime[0];
        if (this.recordTime.length > 1) {
          this.params.endTime = this.recordTime[1];
        }
      }

      //获取设备列表
      this.axios({
        method: "get",
        url: "/v1/web/device-manage/list-device-manage",
        params: params || this.params,
      }).then((res) => {
        this.tableData = res.data.data.list;
        this.total = res.data.data.total;
      });
    },
    queryUserList() {
      //获取师傅下拉
      this.axios({
        method: "get",
        url: "/v1/web/user-info/select-master-drown",
      }).then((res) => {
        this.userList = [];
        // let obj = {}
        // obj.id = "";
        // obj.userName = "全部"
        // this.userList.push(obj)
        for (let i = 0; i < res.data.data.list.length; i++) {
          let userInfo = res.data.data.list[i].userName;
          userInfo = userInfo.substring(0, userInfo.indexOf("("));
          res.data.data.list[i].userName = userInfo;
          this.userList.push(res.data.data.list[i]);
        }
      });
    },
    add() {
      //新增弹出层
      this.dialogFormAdd = true;
      this.isAddBatch = false;
      this.deviceManageArray = {};
    },
    addBatch() {
      //新增弹出层
      this.dialogFormAdd = true;
      this.isAddBatch = true;
      this.deviceManageArray = {};
    },
    addClick(formName) {
      if (this.isAddBatch) {
        let tmpArr = this.deviceManageArray.deviceNums.split(/[(\r\n)\r\n]+/);
        let numList = [];
        for (let i = 0; i < tmpArr.length; i++) {
          let item = tmpArr[i];
          if (!item || !item.replace(/[\t\r\f\n\s]*/g, "")) {
            continue;
          }
          numList.push(item.replace(/[\t\r\f\n\s]*/g, ""));
        }

        console.log(numList);
        this.deviceManageArray.count = numList.length;
        if (this.isRepeat(numList)) {
          this.$message.error("存在重复的充电桩编号");
          return;
        }
        let tips = "".concat(
          "当前输入的充电桩编号有",
          numList.length,
          "个,具体为:",
          numList.join(",\r\n"),
          ",\r\n是否确认?"
        );
        this.$confirm(tips, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.deviceManageArray.deviceNumList = numList;
            this.addSubMit(formName);
          })
          .catch(() => {});
      } else {
        this.addSubMit(formName);
      }
    },
    addSubMit(formName) {
      //新增提交
      if (
        this.deviceManageArray.deviceNum != null &&
        this.deviceManageArray.deviceNum != ""
      ) {
        this.deviceManageArray.count = 1;
      }

      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.axios({
            method: "post",
            url: "/v1/web/device-manage/add-device-manage",
            data: this.deviceManageArray,
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormAdd = false;
              this.$message({ message: "添加成功", type: "success" });
              this.queryDeviceManageList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    edit(row) {
      //修改弹出层
      this.deviceManageArray = { ...row };
      console.log(this.deviceManageArray);
      let urlArr = [];
      this.deviceManageArray.imageList = [];
      if (this.deviceManageArray.imageUrl !== null) {
        urlArr = this.deviceManageArray.imageUrl.split(",");
        for (let i = 0; i < urlArr.length; i++) {
          let imgObj = { name: "设备图片" + i, url: this.changeImageUrl(urlArr[i]) };
          this.deviceManageArray.imageList.push(imgObj);
        }
      }

      this.dialogFormEdit = true;
    },
    editSubMit(formName) {
      //修改提交
      if (
        this.deviceManageArray.imageList != null &&
        this.deviceManageArray.imageList.length > 0
      ) {
        let urlArr = [];
        for (let i = 0; i < this.deviceManageArray.imageList.length; i++) {
          let image = this.deviceManageArray.imageList[i];
          urlArr.push(this.resetImageUrl(image.url));
        }
        this.deviceManageArray.imageUrl = urlArr.join(",");
      }

      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.axios({
            method: "post",
            url:
              "/v1/web/device-manage/update-device-manage/" + this.deviceManageArray.id,
            data: this.deviceManageArray,
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormEdit = false;
              this.$message({ message: "修改成功", type: "success" });
              this.queryDeviceManageList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    deleteDevice(row) {
      //删除设备
      this.axios({
        method: "delete",
        url: "/v1/web/device-manage/delete-device-manage/" + row.id,
        params: {
          id: row.id,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.queryDeviceManageList();
        } else {
          this.$message({ type: "error", message: res.data.message });
        }
      });
    },
    distributionSubMit(formName) {
      //分配提交
      if (
        this.deviceManageArray.selectDeviceList != null &&
        this.deviceManageArray.selectDeviceList.length > 0
      ) {
        this.deviceManageArray.deviceIdList = [];
        for (let i = 0; i < this.deviceManageArray.selectDeviceList.length; i++) {
          this.deviceManageArray.deviceIdList.push(
            this.deviceManageArray.selectDeviceList[i].id
          );
        }
      }
      // 存储排序数据
      saveUserSortSimple(this.deviceManageArray.haveUser, this.sortUserList, STORE_KEY);
      this.sortUserList = getBData(STORE_KEY);
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.axios({
            method: "post",
            url: "/v1/web/device-manage/distribution-device-manage",
            data: this.deviceManageArray,
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormDistribution = false;
              this.$message({ message: "分配成功", type: "success" });
              this.queryDeviceManageList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    distribution() {
      //分配提示/弹出层
      this.deviceManageArray = {};
      this.deviceManageArray.selectDeviceList = null;
      if (this.multipleSelection != null || this.multipleSelection.length > 0) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          let row = this.multipleSelection[i];
          if (row.status !== 0) {
            this.$message({
              message: "存在状态不为'未使用'的充电桩，不能进行分配",
              type: "error",
            });
            return;
          }
        }

        this.deviceManageArray.selectDeviceList = [...this.multipleSelection];
      }

      this.dialogFormDistribution = true;
    },
    unDistribution() {
      //取消分配
      if (this.multipleSelection == null || this.multipleSelection.length <= 0) {
        this.$message({ message: "请选择对应需要取消分配的充电桩", type: "error" });
        return;
      }

      let deviceIdList = [];
      for (let i = 0; i < this.multipleSelection.length; i++) {
        let row = this.multipleSelection[i];
        if (row.status !== 0) {
          this.$message({
            message: "存在状态不为'未使用'的充电桩，不能进行分配",
            type: "error",
          });
          return;
        }

        deviceIdList.push(row.id);
      }

      this.$confirm("是否确定取消分配?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.axios({
            method: "post",
            url: "/v1/web/device-manage/un-distribution-device",
            data: { deviceIdList: deviceIdList },
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormBorrow = false;
              this.$message({ message: "操作成功", type: "success" });
              this.queryDeviceManageList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        })
        .catch(() => {});
    },
    borrow() {
      this.dialogFormBorrow = true;
      this.deviceManageBorrow = {};
      this.getDeviceByCategory();
    },
    borrowSubMit(formName) {
      //借出提交
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          return false;
        } else {
          this.axios({
            method: "post",
            url: "/v1/web/device-manage/add-device-sub",
            data: this.deviceManageBorrow,
          }).then((res) => {
            if (res.data.code === 200) {
              this.dialogFormBorrow = false;
              this.$message({ message: "转借成功", type: "success" });
              this.queryDeviceManageList();
            } else {
              this.$message.error(res.data.message);
            }
          });
        }
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    dateFormat: function (row, column, cellValue) {
      if (cellValue !== null && cellValue !== undefined) {
        return moment(cellValue).format("YYYY-MM-DD HH:mm:ss");
      }
    },
    statusFormat: function (row) {
      if (row.status === 0) {
        return "未使用";
      } else if (row.status === 1) {
        return "已使用";
      } else if (row.status === 2) {
        return "转借中";
      }
    },
    isBadFormat: function (row) {
      if (row.isBad === 1) {
        return "是";
      } else {
        return "否";
      }
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.queryDeviceManageList();
    },
    sizeChange(val) {
      this.params.size = val;
      this.params.page = 1;
      this.queryDeviceManageList();
    },
    toDayList(isCurrentUser) {
      this.params = { page: 1, size: 10 };
      this.recordTime = [];

      if (this.isTodayQuery) {
        this.reset();
        return;
      }
      this.isTodayQuery = true;
      let params = { page: 1, size: 10 };
      if (isCurrentUser) {
        params.distributionUser = Number(sessionStorage.getItem("userId"));
      }

      // 设置今日0点0分0秒时间戳 及 今日23:59:59时间
      params.distributionTime = new Date().setHours(0, 0, 0, 0);
      this.queryDeviceManageList(params);
    },
    checkData() {
      this.isTodayQuery = false;
      if (this.params.categoryId === -1) {
        this.params.categoryId = "";
      }
      this.params.distributionUser = "";
      this.queryDeviceManageList();
    },
    reset() {
      this.isTodayQuery = false;
      this.params = { page: 1, size: 10 };
      this.recordTime = [];
      this.queryDeviceManageList();
    },
    isRepeat(arr) {
      var hash = {};
      for (var i in arr) {
        if (hash[arr[i]]) {
          return true;
        }
        hash[arr[i]] = true;
      }
      return false;
    },
    upload(file, fileList) {
      if (fileList.length >= 40) {
        this.$message.error("最多只能上传40张照片");
        return;
      }
      this.clickUpload(file);
    },
    async clickUpload(file) {
      let fileRaw;
      let maxSize = 200 * 1024; //自己定义的文件大小，超过多少M就开始压缩(现在是200k)
      if (file.size > maxSize) {
        fileRaw = await this.compressFile(file.raw, 0.2);
        file.raw = fileRaw;
      }

      this.uploadImage(file);
    },
    uploadImage(file) {
      let obj = {};
      obj.name = file.name;
      let param = new FormData(); //创建form对象
      param.append("files", file.raw);
      param.append("orderNum", this.deviceManageArray.id);
      this.axios({
        method: "post",
        url: "/v1/h5/order-info/image-upload",
        data: param,
      })
        .then((res) => {
          let imgUrl = res.data.data.imageUrl;
          obj.url = this.changeImageUrl(imgUrl);
          this.deviceManageArray.imageList.push(obj);
        })
        .catch((e) => {
          console.log(e);
          this.$message.error("上传失败");
        });
    },
    commRemove(file) {
      let j = 0;
      for (let i = 0; i < this.deviceManageArray.imageList.length; i++) {
        if (file.name === this.deviceManageArray.imageList[i].name) {
          j = i;
        }
      }
      this.deviceManageArray.imageList.splice(j, 1);
    },
    handlePictureCardPreview(file) {
      this.imagePreviewUrl = file.url;
      this.dialogImagePreview = true;
    },
    changeImageUrl(imageUrl) {
      //转换图片路径
      if (imageUrl === null || imageUrl === "") {
        return "#";
      }
      return "/api/v1/web/order-info/preview-image?path=" + imageUrl;
    },
    resetImageUrl(imageUrl) {
      //转换为相对路径
      if (imageUrl === null || imageUrl === "") {
        return "";
      }
      return imageUrl.replace("/api/v1/web/order-info/preview-image?path=", "");
    },
    getImageList(urls) {
      if (urls == null || urls == "") {
        return [];
      }

      let arr = urls.split(",");
      let urlArr = [];
      for (let i = 0; i < arr.length; i++) {
        let url = arr[i];
        urlArr.push(this.changeImageUrl(url));
      }

      return urlArr;
    },
    /**
     * 压缩图片
     * @param file
     * @returns {Promise<unknown>}
     */
    compressFile(file, quality) {
      return new Promise((resolve, reject) => {
        new ImageCompressor(file, {
          quality: quality, //压缩质量
          checkOrientation: false, //图片翻转，默认为false
          success(result) {
            resolve(result);
          },
          error(e) {
            reject(e);
          },
        });
      });
    },
  },
};
</script>

<style scoped></style>
